import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@mui/material';
import useAuth from '../hooks/useAuth';

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { isDomainOkay, user } = useAuth();
  const headerName = isDomainOkay ? `${user.shop.name}` : 'SelPay';

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title} | {headerName}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
