import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sales: [],
    sortBy: null,
    filters: {
        method: ''
    }
};

const slice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setSaleSuccess(state, action) {
            const sales = state.sales.filter((sale) => parseInt(sale.rid, 10) !== parseInt(action.payload.rid, 10));
            sales.push(action.payload);
            state.sales = sales;
        },
        removeSaleSuccess(state, action) {
            if (action.payload > -1) {
                state.sales.splice(action.payload, 1);
            }
        },
        removeSaleIndexSuccess(state, action) {
            const sales = state.sales.filter((sale) => parseInt(sale.rid, 10) !== parseInt(action.payload, 10));
            state.sales = sales;
        },
        //  SORT & FILTER POSTS
        sortBy(state, action) {
            state.sortBy = action.payload;
        },
        filter(state, action) {
            state.filters.method = action.payload.method;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filter,
    sortBy
} = slice.actions;

export function setSale(sale) {
    return (dispatch) => {
        try {
            dispatch(slice.actions.setSaleSuccess(sale));
        } catch (error) {
            // console.log(error);
        }
    };
}

export function removeSaleIndex(rid) {
    return (dispatch) => {
        try {
            dispatch(slice.actions.removeSaleIndexSuccess(rid));
        } catch (error) {
            // console.log(error);
        }
    };
}

export function removeSale(index) {
    return (dispatch) => {
        try {
            dispatch(slice.actions.removeSaleSuccess(index));
        } catch (error) {
            // console.log(error);
        }
    };
}
